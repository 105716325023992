<template>
  <v-container>
    <v-alert v-model="hasError">
      <v-layout row align-center>
        {{ error }}
        <v-spacer />
        <v-btn icon @click="error = ''"><v-icon small>close</v-icon></v-btn>
      </v-layout>
    </v-alert>
    <confirm-dialogue ref="confirm" />
    <v-layout row>
      <h1 v-if="isNew">{{ 'New ' + itemName }}</h1>
      <h1 v-else>{{ itemName + ' Details' }}</h1>
      <v-spacer />
      <v-btn
        light
        @click="startUpdate"
        v-if="!isNew"
        :disabled="updateInProgress"
        >Update</v-btn
      >
      <v-btn color="red" @click="confirmDelete" v-if="!isNew">Remove</v-btn>
    </v-layout>
    <slot name="form" :readonly="true" />
    <v-btn light v-if="isNew" @click="confirmCreate">Save</v-btn>
    <v-btn light v-if="updateInProgress" @click="confirmUpdate">Save</v-btn>
    <v-btn light v-if="updateInProgress" @click="cancelUpdate">Cancel</v-btn>
    <v-btn :to="redirectBack">{{ isNew ? 'Cancel' : 'Back' }}</v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'thirdPartyGenericDetails',
    props: {
      id: {
        type: String,
        default() {
          return '';
        },
      },
      itemName: {
        type: String,
        required: true,
      },
      isNew: {
        type: Boolean,
        default() {
          return false;
        },
      },
      updateInProgress: {
        type: Boolean,
        default() {
          return false;
        },
      },
      redirectBack: {
        type: String,
      },
      maxConfirmWidthOverride: {
        type: Number,
      },
      createNew: {
        type: Function,
      },
      startUpdate: {
        type: Function,
      },
      cancelUpdate: {
        type: Function,
      },
      updateOld: {
        type: Function,
      },
      deleteOld: {
        type: Function,
      },
    },
    data() {
      return {
        error: '',
        hasError: false,
      };
    },
    watch: {
      error: function (val) {
        if (val) {
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      },
    },
    methods: {
      confirmCreate() {
        let options = {};
        if (this.maxConfirmWidthOverride) {
          options.width = this.maxConfirmWidthOverride;
        }

        this.$refs.confirm
          .open(
            `Create ${this.itemName}`,
            `Are you sure you want to create this ${this.itemName}?`,
            options
          )
          .then((confirm) => {
            if (confirm) {
              this.$store.dispatch('refreshIfNeeded').then(() => {
                this.createNew()
                  .then((redirect) => {
                    if (redirect) {
                      this.$router.push(redirect);
                      this.setError();
                      this.isNew = false;
                    }
                  })
                  .catch((error) => {
                    this.setError(error);
                  });
              });
            }
          });
      },
      confirmUpdate() {
        let options = {};
        if (this.maxConfirmWidthOverride) {
          options.width = this.maxConfirmWidthOverride;
        }

        this.$refs.confirm
          .open(
            `Update ${this.itemName}`,
            `Are you sure you want to update this ${this.itemName}?`,
            options
          )
          .then((confirm) => {
            if (confirm) {
              this.$store.dispatch('refreshIfNeeded').then(() => {
                this.updateOld()
                  .then((redirect) => {
                    if (redirect) {
                      this.$router.push(redirect);
                      this.setError();
                      this.isNew = false;
                    }
                  })
                  .catch((error) => {
                    this.setError(error);
                  });
              });
            }
          });
      },
      confirmDelete() {
        let options = {
          verification: [
            {
              kind: 'text',
              label: `Type the ${this.itemName} ID to verify`,
              valid: this.id,
            },
          ],
        };
        if (this.maxConfirmWidthOverride) {
          options.width = this.maxConfirmWidthOverride;
        }
        this.$refs.confirm
          .open(
            `Delete ${this.itemName}`,
            `Are you sure you want to delete ${this.itemName} ${this.id}?`,
            options
          )
          .then((confirm) => {
            if (confirm) {
              this.$store.dispatch('refreshIfNeeded').then(() => {
                this.deleteOld()
                  .then(() => {
                    this.$router.push(this.redirectBack);
                    this.setError();
                  })
                  .catch((error) => {
                    this.setError(error);
                  });
              });
            }
          });
      },
      setError(error) {
        if (typeof error == 'undefined') {
          this.error = '';
        } else {
          this.error = '' + error;
        }
      },
    },
  };
</script>
