<template>
  <v-container>
    <h3>k-ID Provider Credentials</h3>
    <v-spacer />
    <v-text-field
      label="k-ID Web API Key"
      v-model="kidProviderWebApiKey"
      :disabled="formDisabled()"
    />
  </v-container>
</template>

<script>
  export default {
    name: 'KidProviderForm',
    props: [
      'parentalConsentProviderID',
      'formData',
      'savedFormData',
      'updateInProgress',
    ],
    data() {
      return {
        kidProviderWebApiKey: '',
      };
    },
    mounted: function () {
      if (this.formData != '') {
        this.kidProviderWebApiKey = this.formData.kidProviderWebApiKey;
      }
    },
    watch: {
      kidProviderWebApiKey: {
        handler() {
          this.formUpdated();
        },
      },
    },
    methods: {
      formDisabled() {
        //Ensures that when an update is canceled, the form reverts to the saved data, but also that we don't save blank credentials
        if (this.parentalConsentProviderID !== '' && !this.updateInProgress) {
          this.kidProviderWebApiKey = this.savedFormData.kidProviderWebApiKey;
        }
        return this.parentalConsentProviderID !== '' && !this.updateInProgress;
      },
      formUpdated() {
        const credentials = {
          kidProviderWebApiKey: this.kidProviderWebApiKey,
        };
        this.$emit('formUpdated', credentials);
      },
    },
  };
</script>
