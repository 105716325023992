<template>
  <v-container>
    <thirdPartyGenericDetails
      :id="parentalConsentProviderID"
      itemName="Parental Consent Provider"
      :redirectBack="`/developer/domain/${domainID}/game/${gameID}`"
      :isNew="newParentalConsentProvider"
      :maxConfirmWidthOverride="380"
      :updateInProgress="updateInProgress"
      :createNew="createParentalConsentProvider"
      :startUpdate="startUpdate"
      :cancelUpdate="cancelUpdate"
      :updateOld="updateParentalConsentProvider"
      :deleteOld="deleteParentalConsentProvider"
    >
      <template slot="form">
        <v-layout row>
          <v-text-field label="Domain" v-model="domainID" disabled />
          <v-spacer />
          <v-text-field label="Game" v-model="gameID" disabled />
        </v-layout>
        <v-text-field
          label="Parental Consent Provider ID"
          v-model="parentalConsentProviderID"
          disabled
          placeholder="[generated by the server]"
        ></v-text-field>
        <h2>Parental Consent Provider Details</h2>
        <v-select
          v-if="newParentalConsentProvider"
          label="Parental Consent Provider"
          v-model="currProvider"
          :items="providersList"
        >
        </v-select>
        <!-- Add new parental consent provider forms here -->
        <kid-provider-form
          v-if="currProvider === 'k-ID' || currProvider === 1"
          @formUpdated="updateCredentials($event)"
          :parentalConsentProviderID="parentalConsentProviderID"
          :formData="credentials"
          :savedFormData="savedCredentials"
          :updateInProgress="updateInProgress"
        />
      </template>
    </thirdPartyGenericDetails>
  </v-container>
</template>
<script>
  import thirdPartyGenericDetails from './ThirdPartyGenericDetails.vue';
  import kidProviderForm from './KidProviderForm.vue';
  import axios from 'axios';

  export default {
    name: 'ParentalConsentProviderDetails',
    components: {
      thirdPartyGenericDetails,
      kidProviderForm,
    },
    props: ['parentalConsentProvider', 'gameID', 'domainID'],
    data() {
      return {
        newParentalConsentProvider: false,
        currProvider: '',
        providersList: ['k-ID'], // Add new providers here. You will need to make a new component for the respective form as well e.g. KidProviderForm
        credentials: '',
        savedCredentials: '',
        parentalConsentProviderID: '',
        error: '',
        updateInProgress: false,
      };
    },
    mounted: function () {
      if (
        typeof this.parentalConsentProvider === 'undefined' ||
        !this.parentalConsentProvider
      ) {
        this.newParentalConsentProvider = true;
      } else {
        this.newParentalConsentProvider = false;
        this.getDetails();
      }
    },
    watch: {
      error: function (val) {
        this.hasError = val ? true : false;
      },
    },
    methods: {
      updateParentalConsentProviderID(id) {
        this.parentalConsentProviderID = id;
      },
      getDetails() {
        this.$store.dispatch('refreshIfNeeded').then(() => {
          let provider;
          switch (this.parentalConsentProvider) {
            case 'k-ID':
              provider = 1;
              break;
            default:
              break;
          }

          let req = `${this.$store.getters.serviceURL}/admin/developer/game/${this.gameID}/parental-consent-provider/${provider}`;
          axios
            .get(req, this.$store.getters.bearerAuthHeaders)
            .then((response) => {
              this.updateParentalConsentProviderID(
                response.data.parentalConsentProviderID
              );
              this.parentalConsentProviderID =
                response.data.parentalConsentProviderID;
              this.currProvider = this.parentalConsentProvider;

              switch (this.currProvider) {
                case 'k-ID':
                  this.credentials =
                    response.data.credentials.KidProviderCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                default:
                  break;
              }
            })
            .catch((error) => {
              this.error = error;
            });
        });
      },
      createParentalConsentProvider() {
        return new Promise((_resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/parental-consent-provider`;

          let newParentalConsentProvider = {};
          switch (this.currProvider) {
            case 'k-ID':
              newParentalConsentProvider = {
                gameID: this.gameID,
                parentalConsentProviderType: 1,
                credentials: {
                  webApiKey: this.credentials.kidProviderWebApiKey,
                },
              };
              break;
            default:
              break;
          }

          axios
            .post(
              req,
              newParentalConsentProvider,
              this.$store.getters.bearerAuthHeaders
            )
            .then((response) => {
              this.credentials = response.data.credentials;
              this.currProvider = response.data.parentalConsentProviderType;
              this.parentalConsentProviderID =
                response.data.parentalConsentProviderID;

              switch (this.currProvider) {
                case 1:
                  this.currProvider = 'k-ID';
                  this.credentials =
                    response.data.credentials.KidProviderCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                default:
                  break;
              }

              this.$router.push(
                `/developer/domain/${this.domainID}/game/${this.gameID}/parental-consent-provider/${this.currProvider}`
              );
              this.newParentalConsentProvider = false;
            })
            .catch((error) => {
              this.error = error;
              reject(error);
            });
        });
      },
      deleteParentalConsentProvider() {
        let provider;
        switch (this.parentalConsentProvider) {
          case 'k-ID':
            provider = 1;
            break;
          default:
            break;
        }
        return new Promise((resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/game/${this.gameID}/parental-consent-provider/${provider}`;
          axios
            .delete(req, this.$store.getters.bearerAuthHeaders)
            .then(() => {
              resolve(`/developer/domain/${this.domainID}/game/${this.gameID}`);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      startUpdate() {
        this.updateInProgress = true;
      },
      cancelUpdate() {
        this.updateInProgress = false;
      },
      updateParentalConsentProvider() {
        let provider;
        switch (this.parentalConsentProvider) {
          case 'k-ID':
            provider = 1;
            break;
          default:
            break;
        }
        return new Promise((_resolve, reject) => {
          let req = `${this.$store.getters.serviceURL}/admin/developer/game/${this.gameID}/parental-consent-provider/${provider}`;

          let updatedParentalConsentProvider = {};
          switch (this.currProvider) {
            case 'k-ID':
              updatedParentalConsentProvider = {
                credentials: {
                  webApiKey: this.credentials.kidProviderWebApiKey,
                },
              };
              break;
            default:
              break;
          }

          axios
            .put(
              req,
              updatedParentalConsentProvider,
              this.$store.getters.bearerAuthHeaders
            )
            .then((response) => {
              this.credentials = response.data.credentials;
              this.currProvider = response.data.parentalConsentProviderType;
              this.parentalConsentProviderID =
                response.data.parentalConsentProviderID;

              switch (this.currProvider) {
                case 1:
                  this.currProvider = 'k-ID';
                  this.credentials =
                    response.data.credentials.KidProviderCredentials;
                  this.savedCredentials = this.credentials;
                  break;
                default:
                  break;
              }
              this.newParentalConsentProvider = false;
              this.updateInProgress = false;
            })
            .catch((error) => {
              this.error = error;
              reject(error);
            });
        });
      },
      updateCredentials(newData) {
        this.credentials = newData;
      },
    },
  };
</script>
